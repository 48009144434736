import React, {useEffect, useState} from 'react';
import './MapView.css';
import MapComponent from "../../components/Map/MapComponent";
import {fetchMinMaxSun} from "../../services/apiService";
import {jsonizeTooltipContent} from "../../services/shared";
import InformationSidebar from "../../components/Map/InformationSidebar";

function MapView() {
    let [minSun, setMinSun] = useState(0);
    let [maxSun, setMaxSun] = useState(8784);
    let [minPrice, setMinPrice] = useState(0)
    let [maxPrice, setMaxPrice] = useState(100)
    let [minSurface, setMinSurface] = useState()
    let [maxSurface, setMaxSurface] = useState()
    let [forcedMaxPrice, setForcedMaxPrice] = useState(maxPrice)
    let [mapFilters, setMapFilters] = useState({minSun: Number, maxSun: Number});
    let [isSunChecked, setIsSunChecked] = useState(true);
    let [isPriceChecked, setIsPriceChecked] = useState(false);
    let [isPLUChecked, setIsPLUChecked] = useState(true)
    let [isEnedisChecked, setIsEnedisChecked] = useState(false)

    let [isSatelliteChecked, setIsSatelliteChecked] = useState(true)

    const [coefficientA, setCoefficientA] = useState(1);
    const [coefficientB, setCoefficientB] = useState(1);

    const [sidebar, setSidebar] = useState(null)

    const handleCoefficientChange = (e, setterFunction) => {
        const newValue = parseFloat(e.target.value);
        if (!isNaN(newValue)) {
            setterFunction(newValue);
        }
    };

    let [mapSetters, setMapSetters] = useState({
        setMinSun, setMaxSun, setIsSunChecked,
        setMinPrice,setMaxPrice, setIsPriceChecked,
        setMinSurface, setMaxSurface, setIsPLUChecked,
        setIsEnedisChecked,
        setSidebar
    })

    useEffect(() => {
        setMapFilters({
            minSun: minSun, maxSun: maxSun,
            minPrice: minPrice, maxPrice: forcedMaxPrice,
            departementVisibility: isSunChecked,
            communeVisiblity: isPriceChecked,
            enedisVisibility: isEnedisChecked
        });
    }, [minSun, maxSun, minPrice, forcedMaxPrice, isSunChecked, isPriceChecked, isEnedisChecked]);


    return (
        <div className="map-view-container">
            <div className="map-component-container">
                <MapComponent filters={mapFilters} setters={mapSetters}
                              setMaxSun={setMaxSun} setMinSun={setMinSun}
                              isSatelliteChecked={isSatelliteChecked}
                />
            </div>
            <div className="sidebar">
                {sidebar ?
                    <div className="sidebar-section">
                        <button className="btn-close position-absolute" onClick={() => setSidebar(undefined)}></button>
                        <h3>Informations</h3>
                        <InformationSidebar properties={sidebar}/>
                        {/*<div>{jsonizeTooltipContent(sidebar)}</div>*/}
                        <details>
                            <summary>Afficher les détails</summary>
                            <div>
                                {jsonizeTooltipContent(sidebar)}
                            </div>
                        </details>
                    </div> :
                    <>
                        <div className="sidebar-section">
                            <h3>Affichage</h3>
                        <div className="display-selector">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="display-sun"
                                       checked={isSatelliteChecked}
                                       onChange={(e) => setIsSatelliteChecked(!isSatelliteChecked)}
                                />
                                <label className="form-check-label" htmlFor="display-sun">
                                    Satellite
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="display-sun"
                                       checked={isSunChecked}
                                       onChange={(e) => setIsSunChecked(!isSunChecked)}
                                />
                                <label className="form-check-label" htmlFor="display-sun">
                                    Ensoleillement
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="display-dvf-price"
                                       checked={isPriceChecked}
                                       onChange={(e) => setIsPriceChecked(!isPriceChecked)}
                                />
                                <label className="form-check-label" htmlFor="display-dvf-price">
                                    Prix au m² (DVF)
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="display-plu"
                                       checked={isPLUChecked}
                                       onChange={(e) => setIsPLUChecked(!isPLUChecked)}
                                />
                                <label className="form-check-label" htmlFor="display-plu">
                                    Données du PLU
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="display-enedis"
                                       checked={isEnedisChecked}
                                       onChange={(e) => setIsEnedisChecked(!isEnedisChecked)}
                                />
                                <label className="form-check-label" htmlFor="display-enedis">
                                    Points de raccordement ENEDIS
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-section">
                        <h3>Filtres</h3>
                        <div className="range-selector">
                            <span>Soleil :</span>
                            <div>
                                <input type="range" className="form-range" min="0" max="8784" value={minSun}
                                       id="rangeMinSun"
                                       onChange={(e) => setMinSun(parseInt(e.target.value))}/>
                                <label htmlFor="rangeMinSun">{minSun} heures/an</label>
                            </div>
                            <span>à</span>
                            <div>
                                <input type="range" className="form-range" min="0" max="8784" value={maxSun} id="rangeMaxSun"
                                       onChange={(e) => setMaxSun(parseInt(e.target.value))}/>
                                <label htmlFor="rangeMaxSun">{maxSun} heures/an</label>
                            </div>
                        </div>
                        <div className="range-selector">
                            <span>Prix :</span>
                            <div>
                                <input type="range" className="form-range" min="0" max="100" value={minPrice} id="rangeMinPrice"
                                       onChange={(e) => setMinPrice(parseInt(e.target.value))}/>
                                <label htmlFor="rangeMinSun">{minPrice} €/m²</label>
                            </div>
                            <span>à</span>
                            <div>
                                <input type="range" className="form-range" min="0" max="100" value={forcedMaxPrice} id="rangeMaxPrice"
                                       onChange={(e) => setForcedMaxPrice(parseInt(e.target.value))}/>
                                <label htmlFor="rangeMaxSun">{forcedMaxPrice} €/m²</label>
                            </div>
                        </div>
                    </div>
                </>}
            </div>
        </div>
    );
}

export default MapView